import React from 'react';
import PropTypes from 'prop-types';
import RadioButton from './../atoms/forms/RadioButton';

const CardOption = ({ className, subClass = '', isLabel, isSelected, radioGroupName, handlerRadioChange, optionValue, children,
    value, data = '', dataAutomation, isDisabled = false }) => {
    return (
        <label className={`${className} flex items-start cursor-pointer shadow-sm relative bg-basic-white gap-3 hover:bg-primary1-50 transition-regular`}>
            <div className={`${subClass} flex gap-3 items-center flex-shrink-0`}>

                <RadioButton
                    isLabel={isLabel}
                    isSelected={isSelected}
                    radioGroupName={radioGroupName}
                    handlerRadioChange={handlerRadioChange}
                    value={value}
                    data={data}
                    optionValue={optionValue}
                    dataAutomation={dataAutomation}
                    isDisabled = {isDisabled}
                />

            </div>

            <div className='flex-grow'>
                {children}
            </div>
        </label>
    )
}

CardOption.defaultProps = {
    className: '',
    isLabel: true,
    selectedOption: '',
}

CardOption.propTypes = {
    className: PropTypes.string,
    /**
     * When the card does not needs to be wrapped in a label then set to `false`.
     * default value is `true`
     */
    isLabel: PropTypes.bool,
    /**
     - If you you want to keep this radio button selected then keep it's value equal to the `optionValue` property otherwise keep it empty.
     - ex. selected: `male`
     */
    selectedOption: PropTypes.string,
    /**
     * When you use multiple radio button it's necessary to fill this field.
     * for ex. if you use `Male` and `female` then you fill both `radioGroupName` to `gender`.
     */
    radioGroupName: PropTypes.string,
    /**
     * Put here your setter function.
     */
    handlerRadioChange: PropTypes.func,
}

export default CardOption
