import Alert from 'molecules/Alert';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Controller } from "react-hook-form";
import { GetPincodeData, GetServiceblePincode } from 'services/user.service';
import Modal from '../../atoms/Modal';
import Button from './../../atoms/Button';
import ReactSelect from './../../atoms/forms/ReactSelect';
import Input from './../../atoms/Input';
import Checkbox from './../../molecules/Forms/Checkbox';
import BodyForModal from './BodyForModal';
import FooterForModal from './FooterForModal';
import HeaderForModal from './HeaderForModal';
const appId = process.env.NEXT_PUBLIC_APP_ID;

import { CHANNEL } from 'constant/warehouse_pincode_mapping';
import { getAuth } from 'services/identity.service';
import { useRouter } from 'next/router';
import { handleAnalyticEventHandleAddressUpdate } from 'utils/ga-analytic';
import googleAnalytics from "../../assets/mo_engage/order_flow_ga.json"
import { useAddressContext } from 'components/context/addressContext';

const ModalForAddAddress = ({
    className,
    label,
    setValue,
    setError,
    clearErrors,
    isEditCheckDisabled,
    watch,
    isModalOpen,
    handleModalStateChange,
    stateList,
    billingAddress,
    deliveryAddress,
    setIsModalOpen,
    defaultAddressSelector,
    register,
    handleSubmit,
    onSubmit,
    errors,
    control,
    isDefaultBillingAddress,
    handleBillingAddress,
    isDefaultDeliveryAddress,
    handleDeliveryAddress,
    isEditCheckShipping,
    isEditCheckBilling,
    isEditCheckDisabledDef,
    isForReseller = false,
    mobileWhenNoPhone,
    setStateCode,
    tradeName,
    setTradeName,
    buttonIsDisabled,
    editPageData,
    customerSupportNumber,
    addressAlertMsg,
    setAddressAlertMsg
}) => {
    let router =useRouter()
    const analyticEntity = googleAnalytics.entity;
    const handleCreateAddress = (data) => {
        handleAnalyticEventHandleAddressUpdate({eventName: analyticEntity?.Address_Update?.event_name?.Address_Submit, router})
        setTradeName("")
        onSubmit(data);
    }
    const auth = getAuth();
    const userType = auth?.user?.userType

    const { setIsAddressModal
    } = useAddressContext()

    useEffect(()=>{
        setIsAddressModal(true)
     },[])
     
  
    const handlePincodeData = async (pincode) => {
        const result = await GetPincodeData(pincode || watch('pincode'))
        if (result.entity) {
            const servicableResult = await GetServiceblePincode(result.entity.pincode, isForReseller ? CHANNEL.B2B2C : CHANNEL.B2B)
            if (servicableResult.status) {
                if (result.entity.block == 'NA') {
                    setValue('city', result.entity.district)
                } else {
                    setValue('city', result.entity.block)
                }
                setValue('state', stateList.find(item => item.label.toLowerCase() === result.entity.state.toLowerCase())?.value)
                setStateCode(stateList.find(item => item.label.toLowerCase() === result.entity.state.toLowerCase())?.stateCode)
                clearErrors('pincode')
                clearErrors('state')
                clearErrors('city')

            } else {
                setError('pincode', { message: 'Sorry. NirogStreet is yet to bring its services to your pincode. Please change your address and try again.' })
                return
            }
        }
        else if (!result.status) {
            setError('pincode', { message: 'Enter your Valid 6 digit Pin Code.' })
        }
    }

    useEffect(() => {
        if (!watch('pincode') || watch('pincode') === null) return;

        let pin_code = watch('pincode').toString()
        if (pin_code?.length === 6) {
            handlePincodeData(watch('pincode'))
        }
        if (watch('pincode')?.length < 6) {
            setValue('city', '')
            setValue('state', '')
            setValue('gstNumber','')
            setValue('legalEntityName','')
            setError('gstNumber', { message: '' })
            setError('legalEntityName', { message: '' })
        }
        
    }, [watch('pincode')]);

    useEffect(() => {
        if (watch('gstNumber')?.length < 1) {
            setValue('legalEntityName','')
            setError('legalEntityName', { message: '' })
        }
    }, [watch('gstNumber')]); 

    useEffect(() => {
        setValue('fullName', editPageData?.contact_person_name || editPageData?.patienName );
        setValue('houseNo', editPageData?.address);
        setValue('locality', editPageData?.landmark);
        setValue('pincode', editPageData?.pin_code);
        setValue('gstNumber', editPageData?.gst);
        setValue('legalEntityName', editPageData?.legalEntityName);
        setTimeout(()=>{
            setValue('mobileNo', isForReseller ? (editPageData?.contact_person_phone || editPageData?.patienPhone) :  auth?.user?.mobileNumber);
            setValue('alternateMobileNo', isForReseller ? editPageData?.alt_mobile ? editPageData?.alt_mobile : customerSupportNumber : editPageData?.alt_mobile);
        }, 500)
    }, [editPageData]);
    
    return (
        <Modal
            type="modalTypeZero"
            containerClass={`${isModalOpen || 'hidden'}`}
            className={`${className} max-w-109.5 w-full`}
            handlerModalClose={() => handleModalStateChange()}
            isModalOpen={isModalOpen}
            dataAutomation='add-address'
        >
            <form onSubmit={(e) => { e.preventDefault(); handleSubmit(handleCreateAddress)() }} className='h-full'>
            {addressAlertMsg?.message && <Alert className='top-17 md:top-17 z-10' type={addressAlertMsg.type} effect='slideDown' resetAlertCallback={() => setAddressAlertMsg(null)}>{addressAlertMsg.message}</Alert>}
                <div className='flex flex-col min-h-100 max-h-200 relative'>

                    <HeaderForModal
                        className='flex-shrink-0'
                        isCloseButton={true}
                        handlerModalClose={() => handleModalStateChange()}
                        dataAutomation='add-address'
                    >{label}</HeaderForModal>

                    <div className='relative overflow-y-auto flex-grow customScrollbar py-4'>
                        <BodyForModal type="bodyType1" className="pb-3">

                            <Input
                                type="text"
                                isOnlyText
                                placeholder='Full Name*'
                                register={register}
                                id="fullName"
                                setValue={setValue}
                                error={errors?.fullName}
                                isRequiredField={true}
                            />

                            <Input
                                type="number"
                                isMobileNumber={true}
                                placeholder='Mobile Number*'
                                id="mobileNo"
                                register={register}
                                error={errors?.mobileNo}
                                value={watch('mobileNo')}
                                isRequiredField={true}
                                maxLength={10}
                                setValue={setValue}
                                disabled={ !isForReseller ? true : true}
                            />

                            <Input
                                type="number"
                                isMobileNumber={true}
                                placeholder='Alternate Mobile Number*'
                                id="alternateMobileNo"
                                register={register}
                                value={watch('alternateMobileNo')}
                                error={errors?.alternateMobileNo}
                                isRequiredField={true}
                                maxLength={13}
                                setValue={setValue}
                            />
                            
                            <Alert effect="withoutEffect" type="warning_v3" className="w-full mb-2 z-1">
                                <span className='text-semantic-error_base'>*</span>
                                The address and landmark should not contain &, \ , %, #, ; and should be in English.
                            </Alert>

                            <Input
                                type="text"
                                placeholder='Address*'
                                id="houseNo"
                                register={register}
                                error={errors?.houseNo}
                                isRequiredField={true}
                                minLength={0}
                                maxLength={250}
                            />

                            <Input
                                type="text"
                                placeholder='Landmark*'
                                id="locality"
                                register={register}
                                error={errors?.locality}
                                isRequiredField={true}
                                minLength={0}
                                maxLength={200}
                            />

                            <div className='flex xs:flex-row gap-3.5 xxs:gap-8.5 '>
                                <Input
                                    type="text"
                                    placeholder='Pincode*'
                                    id="pincode"
                                    register={register}
                                    error={errors?.pincode}
                                    isRequiredField={true}
                                    minLength={6}
                                    maxLength={6}
                                    disabled={(`${editPageData?.gstVerified}` == 1 || editPageData?.isResellerDefaultAddress) ? true : false}
                                />
                                <Input
                                    type="text"
                                    placeholder='City*'
                                    id="city"
                                    register={register}
                                    error={errors?.city}
                                    isRequiredField={true}
                                    disabled={(`${editPageData?.gstVerified}` == 1 || editPageData?.isResellerDefaultAddress) ? true : false}
                                />
                            </div>

                            <Controller
                                control={control}
                                name="state"
                                render={({ field: { onChange, name, value } }) => (
                                    <ReactSelect
                                        name={name}
                                        isRequiredField
                                        placeholder="Select State*"
                                        onChange={(data) => {
                                            onChange(data?.value)
                                        }}
                                        value={stateList?.find((c) => c.value === value || null)}
                                        options={stateList}
                                        error={errors?.state}
                                        isDisabled={true}
                                    />
                                )}
                            />

                            {
                                !isForReseller && <Input
                                    type="text"
                                    className='uppercase'
                                    placeholder={`GST Number ${userType == 6 ? '' : `(Optional)`}`}
                                    id="gstNumber"
                                    register={register}
                                    error={errors.gstNumber}
                                    isRequiredField={false}
                                    minLength={15}
                                    maxLength={15}
                                    disabled={`${editPageData?.gstVerified}` == 1 ? true : false}
                                />
                            }
                            {
                                !isForReseller && <Input
                                    type="text"
                                    className='uppercase'
                                    placeholder={`Legal Entity Name  ${userType == 6 ? '' : `(Optional)`}`}
                                    id="legalEntityName"
                                    register={register}
                                    error={errors?.legalEntityName}
                                    isRequiredField={false}
                                    disabled={`${editPageData?.gstVerified}` == 1 ? true : false}
                                />
                            }

                            {
                                defaultAddressSelector ? (null) :
                                    (
                                        <>
                                            <Checkbox
                                                checked={isDefaultBillingAddress}
                                                isDisabled={(!billingAddress?.length || isEditCheckDisabled || isEditCheckBilling) ? true : false}
                                                className="mb-3.5 mt-5"
                                                handlerCheckboxChange={handleBillingAddress}
                                                id="billingAddress"
                                                label="Set Default Billing Address"
                                            />

                                            <Checkbox
                                                checked={isDefaultDeliveryAddress}
                                                isDisabled={(!deliveryAddress?.length || isEditCheckDisabledDef) ? true : false}
                                                className=""
                                                handlerCheckboxChange={handleDeliveryAddress}
                                                id="shippingAddress"
                                                label="Set Default Shipping Address"
                                            />
                                        </>
                                    )
                            }

                        </BodyForModal>
                    </div>


                    <FooterForModal className="px-3.5 xs:px-5 sm:px-8 pt-3 pb-3 md:pb-4 bg-basic-white relative z-10">
                        <Button size='medium' className='rounded-lg max-w-75 w-full mx-auto' dataAutomation='save-address' disabled={buttonIsDisabled}>Save address</Button>
                    </FooterForModal>
                </div>

            </form>
        </Modal>
    )
}

ModalForAddAddress.propTypes = {
    className: PropTypes.string,
    isModalOpen: PropTypes.bool,
    handleModalStateChange: PropTypes.func,
    isAddressModal: PropTypes.bool
}

export default ModalForAddAddress

