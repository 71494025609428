import React from 'react'

const FooterForModal = ({ className, children }) => {
    return (
        <div className={`${className} `}>
            {children}
        </div>
    )
}

export default FooterForModal
